<template>
    <div>
        <div class="total-title__line"></div>
        <div class="total-title">企业规格占比图
            <div class="total-title__right">筛选：
                <ts-year-select v-model="year" @change="showDoseRate()"></ts-year-select>
            </div>
        </div>
        <ts-error v-if="noAuth"></ts-error>
        <div :id="nameKey" class="canvas small" style="width: 90%" v-loading="loading"></div>
    </div>
</template>

<script>
export default {
    props: {
        txt: {},
        isAccurate: {
            default: false,
            type: Boolean,
        },
        baseQuery: {
            default: () => ({})
        },
    },
    created() {
        this.showDoseRate()
    },
    data() {
        return {
            year: this.$variable.lastYear.key,
            noAuth: false,
            loading: false,
            nameKey: new Date().getTime() + 'spec',
            data: [],
        }
    },
    methods: {
        showDoseRate() {
            this.loading = true
            this.$api.post('indexDrug/saleYear/drugSaleDoseRatio', {
                model: this.$variable.yearMap[this.year],
                page: {
                    pageSize: 10,
                    pageNo: 1,
                },
                params: this.baseQuery,
                sort: { ratio: "desc" }
            })
                .then(res => {
                    if (res.code !== '00000') {
                        this.noAuth = true
                    } else {
                        let total = 0
                        this.data = res.data.records
                            .map(item => {
                                const value = this.$help.parseFloat100(item.ratio)
                                item.name = `${item.unifySpec}${item.unifyDose}-${item.compName}-${item.comName}`
                                item.value = value
                                total += value
                                return item
                            })
                            .sort((x, y) => {
                                return parseFloat(y.amount) - parseFloat(x.amount)
                            })
                        if (total < 100 && this.data.length === 10) {
                            this.data.push({
                                name: '其他',
                                value: 100 - total
                            })
                        }
                        this.$help.renderPie(this.nameKey, this.data)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>