<style lang="scss">
.fix-table {

  .el-table__fixed,
  .el-table__fixed-right {
    height: auto !important;
    bottom: 10px !important;
  }
}
</style>

<template>
  <div ref="panel" class="scroll-div" style="position: unset;">
    <ts-table-operate style="margin: 0 20px" :name="nameKey" :table-name="tableName" v-if="cols.length > 0" :cols="cols"
      @handleFilter="handleFilter" show-export @leadingOut="exportInfo()" @handleSort="sort" @viewChange="viewChange"
      @searchShowLeft="searchShowLeft">
      <!-- class="table-operate__right" -->
      <div slot="left" style="margin-right: 10px;">
        （销售额单位：{{ $variable.unit }}）
        <el-radio-group v-model="$parent.type" size="small">
          <el-radio-button :label="1">年</el-radio-button>
          <el-radio-button :label="2">季</el-radio-button>
        </el-radio-group>
      </div>
    </ts-table-operate>
    <ts-table :style="{ height: realHeight + 'px' }" ref="table" zero-to-empty :cols="cols" :data="list"
      :page-param="pageParam" :loading="loading" @handleSort="sort" :error="pageParam.error" :error-code="error"
      :name="nameKey" @handleRowDbClickChange="handleRowDbClickChange" hide-detail is-single full
      @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :summaryMethod="summaryFun">
      <template #searchLeft>
        <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
          @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
      </template>
      <template v-slot:slot_sale="{ props }">
        <el-button class="detail-button" type="primary" round @click="goDrugTotal(props.row)">查看</el-button>
      </template>
    </ts-table>

    <div class="total-title__line"></div>
    <div class="total-title">
      产品销售数据图
      <div class="total-title__right">
        筛选：
        <el-select v-model="canvas.checkedCorp" value-key="id" placeholder="请选择公司" filterable @change="corpChange"
          style="width: 250px; margin-right: 10px">
          <el-option v-for="item in canvas.corpList" :key="item.id" :label="item.compName" :value="item"></el-option>
        </el-select>
        <el-select v-show="canvas.checkedCorp.id && !isAccurate" v-model="canvas.checkDrug" value-key="id"
          placeholder="请选择药品" @change="drugChange" collapse-tags multiple filterable style="width: 300px">
          <el-option v-for="item in canvas.drugList" :key="item.id" :label="`${item.comName}${item.dose ? '-' + item.dose : ''}${item.spec || ''
      }`" :value="item"></el-option>
        </el-select>
      </div>
    </div>

    <ts-error v-if="canvas.message === 405 || error"></ts-error>
    <div :id="nameKey" class="canvas small" v-loading="canvas.loading"></div>

    <drug-sale-info-corp-spec :txt="txt" :isAccurate="isAccurate" :base-query="baseQuery"></drug-sale-info-corp-spec>
    <drug-sale-info-corp-rate :txt="txt" :isAccurate="isAccurate" :base-query="baseQuery"></drug-sale-info-corp-rate>
    <drug-sale-info-dose-rate :txt="txt" :isAccurate="isAccurate" :base-query="baseQuery"></drug-sale-info-dose-rate>
  </div>
</template>

<script>
import myMixin from "@/util/mixin";
import searchMixin from "./searchMixin";
import DrugSaleInfoCorpSpec from "./DrugSaleInfoCorpSpec";
import DrugSaleInfoCorpRate from "./DrugSaleInfoCorpRate";
import DrugSaleInfoDoseRate from "./DrugSaleInfoDoseRate";
export default {
  mixins: [myMixin, searchMixin],
  components: {
    DrugSaleInfoCorpSpec,
    DrugSaleInfoCorpRate,
    DrugSaleInfoDoseRate,
  },
  props: {
    baseQuery: {
      default: () => ({}),
    },
    realHeight: {},
    txt: {},
    isAccurate: {
      default: false,
      type: Boolean,
    },
    visitId: {},
  },
  data() {
    return {
      nameKey: "drugSaleYear",
      nameKey1: "drugSaleYear1",
      tableName: "sale_year",
      pageApi: "indexDrug/saleYear/drugSaleInfoYear",
      yearAllApi: "indexDrug/saleYear/drugSaleInfoYearAll",
      sumData: [],
      cols: this.$help.generalCols([
        this.$variable.indexColumn.drug({ listStipulate: 1 }),
        this.$variable.indexColumn.corp({ listStipulate: 1 }),
        {
          fieldName: "unifyDose",
          name: "剂型",
          listQueryView: "是",
          width: 70,
          listClass: "tc",
          listOrderView: "是",
        },
        {
          fieldName: "unifySpec",
          name: "规格",
          listQueryView: "是",
          width: 70,
          listClass: "tc",
          listQueryModel: 1,
          listStipulate: 1,
          listOrderView: "是",
        },
        ...this.$variable.getYearCols({
          listOrderView: "是",
        }),
        ...this.$variable.years.map((item) => ({
          fieldName: "rate" + item.value,
          name: item.value + "年增长比%",
          listOrderView: "是",
          listClass: "tc",
          width: 60,
        })),
        // ...this.$variable.getYearRateCols(),
      ]),
      active: "",

      canvas: {
        show: false,
        message: 200,
        loading: false,
        model: {},
        product: [],
        all: [],

        corpList: [],
        checkedCorp: {},
        drugList: [],
        checkDrug: [],

        rateLoading: false,
        year: this.$variable.lastYear.key,
        rate: [],
        cols: this.$help.generalCols([
          {
            fieldName: "name",
            name: "名称",
            width: 100,
          },
          {
            fieldName: "ratio",
            name: "百分比",
            width: 50,
            listClass: "tc",
          },
        ]),
      },
    };
  },
  created() {
    // 加载该药品的所有公司
    this.$api
      .post("setting/aimDrugsSaleYear/queryCompNameListByComName", {
        params: this.baseQuery,
      })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.canvas.corpList = [
            {
              id: 0,
              compName: "所有",
            },
          ].concat(res.data);
          this.canvas.checkedCorp = this.canvas.corpList[0];
          this.getDrugByCorp();
        }
      });
  },
  methods: {
    corpChange() {
      if (this.isAccurate) {
        this.showProduct();
      } else {
        this.canvas.checkDrug = [];
        this.getDrugByCorp();
        this.canvas.loading = true;
      }
    },
    getDrugByCorp() {
      if (this.canvas.checkedCorp.id) {
        this.$api.post("setting/aimDrugsSaleYear/queryComList", {
          conditionList: [
            {
              name: "compName",
              op: "like",
              value: this.canvas.checkedCorp.compName,
            },
            {
              name: "comName",
              op: "like",
              value: this.txt,
            },
          ],
        })
          .then((res) => {
            // if (res.data && res.data.length > 0) {
            this.canvas.drugList = [
              {
                id: 0,
                comName: "所有",
              },
            ].concat(res.data);
            this.canvas.checkDrug = [this.canvas.drugList[0]];
            this.drugChange();
            // }
          });
      } else {
        this.showProduct();
      }
    },
    drugChange() {
      const list = this.canvas.checkDrug;
      if (list.length > 0) {
        let flag = false; // 是否包含所有

        for (let i = 0; i < list.length; i += 1) {
          if (list[i].id === 0) {
            flag = true;
            break;
          }
        }
        if (list.length > 1 && flag) {
          if (list[0].id === 0) {
            this.canvas.checkDrug = this.canvas.checkDrug.filter(
              (item) => item.id !== 0
            );
          } else {
            this.canvas.checkDrug = [this.canvas.drugList[0]];
          }
        }
        this.showProduct();
      } else {
        this.$store.commit("error", "请至少选择一条数据");
      }
    },
    showLabel(item) {
      return `${item.compName} ${item.comName}${item.spec ? `-${item.spec}` : ""
        }`;
    },
    remoteMethod(keyword) {
      this.canvas.loading = true;
      this.$api
        .post(this.pageApi, {
          params: {
            comName: this.baseQuery.comName,
            compName: keyword ? `%${keyword}%` : undefined,
          },
        })
        .then((res) => {
          if (res.code === this.$variable.successCode) {
            this.canvas.list = [
              {
                id: "all-data",
                compCode: "all-comp",
                comCode: "all-com",
                comName: "",
                compName: "全部",
              },
            ]
              .concat(res.data.records)
              .map((item) => {
                item.id = item.comCode + item.compCode + item.spec;
                return item;
              });

            if (!this.canvas.model.id) {
              this.canvas.model = this.canvas.list[0];
              this.showProduct();
            }
          }
        })
        .finally(() => {
          this.canvas.loading = false;
        });
    },
    listComputed(list) {
      list.map((item) => {
        this.$variable.years.forEach((year) => {
          const field = `rate${year.value}`;
          item[field] = this.$help.parseFloat100(item[field]);
        });
        return item;
      });
      return list;
    },
    sumDataComputed(list) {
      this.$variable.years.forEach((year) => {
        const field = `rate${year.value}`;
        list[field] = list[field] ? this.$help.parseFloat100(list[field]) : '';
      });
      return list;
    },

    exportInfo(txt = "确定导出吗") {
      const search = this.getParam()
      delete search.sort
      delete search.page
      this.$help.exportInfo(txt, "indexDrug/saleYear/exportDrugSaleInfoYear", search)
    },
    showProduct() {
      this.canvas.loading = true;
      this.canvas.product = [];

      let query = {
        params: this.$help.deepClone(this.baseQuery),
      };

      if (this.canvas.checkedCorp.id) {
        query.params.compName = this.canvas.checkedCorp.compName;

        if (!this.isAccurate && this.canvas.checkDrug[0].id !== 0) {
          const cndCluster = {
            op: "or",
            subClusters: [],
          };
          this.canvas.checkDrug.forEach((item) => {
            const arr = [];
            arr.push({
              name: "comName",
              value: item.comName,
              op: "eq",
            });
            // arr.push({
            //     name: 'spec',
            //     value: item.spec,
            //     op: 'eq'
            // })
            // arr.push({
            //     name: 'dose',
            //     value: item.dose,
            //     op: 'eq'
            // })

            cndCluster.subClusters.push({
              cnds: arr,
              op: "and",
            });
          });
          query.cndCluster = cndCluster;
        }
      }

      this.$api
        .post(this.yearAllApi, query)
        .then((res) => {
          if (res.code !== "00000") {
            this.canvas.message = 405;
          } else {
            this.canvas.product = res.data.map((item) => {
              // item.year = this.$variable.yearMap[item.year]
              item.rate = this.$help.parseFloat100(item.rate);
              return item;
            });
            this.$help.renderBar(this.nameKey, this.canvas.product);
          }
        })
        .finally(() => {
          this.canvas.loading = false;
        });
    },
    showAll() {
      this.active = "all";
      this.canvas.show = true;
      this.canvas.message = "";
      this.$nextTick(() => {
        this.canvas.loading = true;

        if (this.canvas.all.length === 0) {
          this.$api
            .post(this.yearAllApi, { params: { comName: `%${this.txt}%` } })
            .then((res) => {
              if (res.code !== "00000") {
                this.canvas.message = 405;
              } else {
                this.canvas.all = res.data.map((item) => {
                  // item.year = this.$variable.yearMap[item.year]
                  item.rate = this.$help.parseFloat100(item.rate);
                  return item;
                });
                this.$help.renderBar(this.nameKey, this.canvas.all);
              }
            })
            .finally(() => {
              this.canvas.loading = false;
            });
        } else {
          this.canvas.loading = false;
          this.$help.renderBar(this.nameKey, this.canvas.all);
        }
      });
    },
  },
};
</script>